import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Jeeper Pub
			</title>
			<meta name={"description"} content={"У пабі Jeeper Pub кожна страва та напій - це глава в нашій історії про сільський шарм та сучасний смак."} />
			<meta property={"og:title"} content={"Меню | Jeeper Pub"} />
			<meta property={"og:description"} content={"У пабі Jeeper Pub кожна страва та напій - це глава в нашій історії про сільський шарм та сучасний смак."} />
			<meta property={"og:image"} content={"https://flahekinsta.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://flahekinsta.com/media/6949022.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://flahekinsta.com/media/6949022.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://flahekinsta.com/media/6949022.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://flahekinsta.com/media/6949022.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://flahekinsta.com/media/6949022.png"} />
			<meta name={"msapplication-TileImage"} content={"https://flahekinsta.com/media/6949022.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box display="flex" flex-direction="row" padding="0px 0px 50px 0px">
				<Box
					display="flex"
					width="20%"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					lg-align-items="center"
					lg-margin="0px 0px 20px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 0px"
					lg-width="100%"
					md-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						padding="0px 16px 0px 0px"
					>
						Меню
					</Text>
				</Box>
				<Box
					display="flex"
					width="75%"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="28px 0px 16px 16px"
					lg-width="100%"
					md-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL1"
						font="--lead"
						lg-text-align="center"
						display="flex"
						align-items="flex-start"
					>
						У пабі Jeeper Pub кожна страва та напій - це глава в нашій історії про сільський шарм та сучасний смак.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flahekinsta.com/media/m1.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Фірмова тушонка "Ліхтарня"
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Ситне, зігріваюче душу рагу, наповнене ніжним м'ясом та свіжими сезонними овочами - справжня класика на новий лад.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flahekinsta.com/media/m2.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Пироги Heritage Hand Pies
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Листкове тісто, наповнене пікантною начинкою, ідеально підходить для затишного вечора в пабі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flahekinsta.com/media/m3.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Розливне пиво
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Асортимент місцевих та крафтових сортів елю, кожен з яких має власну багату історію та неповторний смак.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flahekinsta.com/media/m4.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Lantern's Libations
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Наші фірмові коктейлі, що поєднують традиційні рецепти з сучасною міксологією - ковток минулого, створений для сьогодення.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flahekinsta.com/media/m5.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Сирна тарілка ремісничих сирів
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Вишукана добірка вишуканих сирів у поєднанні з сільським хлібом та сезонними акомпанементами.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://flahekinsta.com/media/m6.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Сутінкові тости
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Хрусткі золотисті тости з різноманітними вишуканими начинками - від насичених, пікантних варіантів до легких, свіжих смаків.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});